
/* table part 1 */
._NMT_tableButtons {
    width: 100%;
    height: 35px;
    border-bottom: 1px double #EBE8E8;
    /* border-top: 1px double #EBE8E8; */
    margin-bottom: 8px;
}

._NMT_tableButtons ._NMT_tableButItem {
    float: left;
    color: rgb(1,125,230);
    font-size: 14px;
    /* font-weight: bold; */
    line-height: 25px;
    margin-left: 10px;
    margin-right: 20px;
    margin-top: 5px;
    height: 25px;
    cursor: pointer;
}

._NMT_tableButtons ._NMT_tableButItem:hover {
    color: #ec7259;
}

/* table part 2 */
._NMT_tableTop {
    width: 100%;
    margin-bottom: 8px;
}

._NMT_tableTotalRecord {
    font-size: 14px;
    margin-right: 15px;
    color: black;
}

._NMT_Pagination .ant-pagination-item-active{
    background-color: rgb(1,125,230);
    border: 1px double rgb(221, 221, 221) !important;
}

._NMT_Pagination .ant-pagination-item-active a{
    color: white !important;
}

/* table part 3 */

._NMT_table {
    width: 100%;
    overflow: auto;
    /* min-height: 200px; */
}

._NMT_tableMain {
    width: 100%;
    cursor: pointer;
    background-color: rgb(245, 245, 249);
    border-bottom: 1px double #EBE8E8;
    overflow: auto;
}

._NMT_tableMain ._NMT_tableHeader{
    background-color: rgb(221, 221, 221);
}

._NMT_tableMain ._NMT_tableHeader ._NMT_colHeader{
    height: 35px;
    line-height: 30px;
    border-right: 1px white double;
    font-size: 14px;
    color: black;
    text-align: left;
    vertical-align: middle;
    padding-top: 5px;
    padding-left: 5px;
    overflow: hidden;
    word-wrap: break-word;
    word-break: break-word;
}

._NMT_tableMain ._NMT_tableHeader ._NMT_colHeader_checkboxAll{
    height: 35px;
    line-height: 30px;
    border-right: 1px white double;
    font-size: 14px;
    color: black;
    text-align: center;
    vertical-align: middle;
    padding-top: 5px;
    padding-left: 5px;
}

._NMT_tableMain ._NMT_tableRow1{
    background-color: white;
}

._NMT_tableMain ._NMT_tableRow2{
    background-color: rgb(242, 242, 242);
}

._NMT_tableMain ._NMT_tableRow1:hover{
    background-color: rgb(232, 235, 248);
}

._NMT_tableMain ._NMT_tableRow2:hover{
    background-color: rgb(232, 235, 248);
}

._NMT_tableMain ._NMT_tableRow1 ._NMT_colRow{
    min-height: 32px;
    line-height: 25px;
    height: 30px;
    border-right: 1px #EBE8E8 double;
    font-size: 13px;
    vertical-align: middle;
    text-align: left;
    padding-left: 5px;
    padding-right: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* word-wrap: break-word; */
    /* word-break: normal; */
}

._NMT_tableMain ._NMT_tableRow2 ._NMT_colRow{
    min-height: 32px;
    line-height: 25px;
    height: 30px;
    border-right: 1px #EBE8E8 double;
    font-size: 13px;
    vertical-align: middle;
    text-align: left;
    padding-left: 5px;
    padding-right: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* word-wrap: break-word; */
    /* word-break: normal; */
}

._NMT_tableMain ._NMT_colRow .min-colRow-title {
    text-align: right;
    font-size: 14px;
    color: black;
}

._NMT_tableMain ._NMT_colRow .min-colRow-value {
    text-align: left;
    font-size: 14px;
    padding-left: 10px;
    color: #3B3B3B;
}

._NMT_nodata {
    display:none;
    height: 60px;
    text-align: center;
    font-size: 14px;
    vertical-align: middle;
    background-color: rgb(242, 242, 242);
    line-height: 60px;
}

._NMT_DataLoading {
    text-align: center;
}
