/* #topBtn {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 3%;
    z-index: 99;
    border: none;
    outline: none;
    background-color: none;
    cursor:pointer;
    padding: 3px;
    border-radius: 30px;
    font-size: 3px;
  } */

#botBtn {
    display: none;
    position: fixed;
    top: 100px;
    right: 3%;
    z-index: 99;
    border: none;
    outline: none;
    background-color:grey;
    color: white;
    cursor:pointer;
    padding: 3px;
    border-radius: 30px;
    font-size: 3px;
  }

#botBtn:hover{
  background-color: black;
  color: white;
}

.backtop{
    background-Color: #808080;
    color: #fff;
    position: fixed;
    bottom: 20px;
    right: 3%;
    z-index: 99;
    border: none;
    outline: none;
    background-color: none;
    cursor:pointer;
    padding: 3px;
    border-radius: 30px;
    font-size: 3px;
}
.backtop:hover{
  background-color: black;
  color: white;
}