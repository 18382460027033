.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}


.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.ant-table-thead .tableheader{
  background-color: rgb(221, 221, 221);
}

.ant-table-show .ant-table-default{
    width: 82%;
    overflow: auto;
    /* margin-left: 100px; */
    /* min-height: 200px; */
}
.GGEditor-area{
    width: 100%;
    overflow: auto;
    /* margin-left: 120px; */
}

.ant-modal-content{
  width: 900px;
  right: 200px;
}

.menu::-webkit-scrollbar {
  width : 10px;
  }
.menu::-webkit-scrollbar-thumb {

  border-radius: 10px;
  /* box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2); */
  /* background   :#001529; */
  background   : transparent;
  }
.menu::-webkit-scrollbar-track {

  border-radius: 10px;
  background   : transparent;
  }

.Loading {
    text-align: center;
    vertical-align: middle;
    position: absolute;
    top: calc(50% - 4em);
    left: calc(50% - 4em);
}

.versionMsg {
  background: transparent;
  color: lightgrey;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-right: 25px;
  text-align: right;
  position: absolute;
  right: 5px;
  bottom: 10px;
}

.nmt_logo {
  margin: 0px 15px 5px 15px;
}

.ant-layout-sider-collapsed .nmt_logo {
  margin: 0px 15px 5px 28px;
}

.ant-menu .ant-menu-item:nth-child(1) {
  margin-top: 0px;
}