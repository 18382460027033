._NMT_mybutton {
    border-color: '#ec7259';
    color: '#ec7259';
    background-color: '#fff';
}

._NMT_myselect {
    width: 100%;
}

._NMT_myinput {
    width: 100%;
}

._NMT_mytextarea {
    width: 100%;
}

._NMT_myswitch {
    
}

._NMT_mycheckbox {
    
}

._NMT_mydatepicker {
    width: 100%;
}

._NMT_myrangepicker {
    width: 100%;
}